import { usePaginatedQuery } from "@/shared/hooks/usePaginatedQuery";

import type { TNotification } from "../types";

export const NOTIFICATIONS_QUERY_KEY = ["notifications"];

export const useGetNotifications = () => {
  return usePaginatedQuery<TNotification>({
    queryKey: NOTIFICATIONS_QUERY_KEY,
    url: "/api/v1/notificationcenter/notifications/",
  });
};
