import type { QueryKey, UseInfiniteQueryOptions } from "@tanstack/react-query";

import { useAuthContext } from "@/base/auth/providers/AuthProvider";
import { paramsSerializer } from "@/shared/utils/paramsSerializer";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useCallback, useMemo } from "react";

export type PaginatedResponse<T> = { next: string; results: T[] } | T[];

export type UsePaginatedQueryProps<T> = {
  enabled?: boolean;
  headers?: Record<string, string>;
  includePageSizeInRequest?: boolean;
  pageSize?: number;
  queryKeyForItem?: (item: T) => QueryKey;
  url: string;
  urlParams?: Record<string, unknown>;
  withAuthorization?: boolean;
} & Pick<UseInfiniteQueryOptions<T>, "queryKey">;

type PageParam = { initial: true; url: string };

export const usePaginatedQuery = <T>({
  enabled,
  headers,
  includePageSizeInRequest = true,
  pageSize,
  queryKey,
  queryKeyForItem,
  url,
  urlParams,
  withAuthorization = true,
}: UsePaginatedQueryProps<T>) => {
  const { accessToken } = useAuthContext();
  const queryClient = useQueryClient();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch,
    status,
    ...useInfiniteQueryProps
  } = useInfiniteQuery<PaginatedResponse<T>>({
    enabled,
    getNextPageParam: (lastPage) => {
      return "next" in lastPage && lastPage.next
        ? { url: lastPage.next }
        : null;
    },
    initialPageParam: { initial: true, url },
    queryFn: async ({ pageParam }) => {
      const queryFnPageParams = pageParam as PageParam;
      const queryHeaders: Record<string, string> = { ...headers };

      if (withAuthorization && accessToken) {
        queryHeaders.Authorization = `Bearer ${accessToken}`;
      }

      const params: Record<string, unknown> = {
        page_size:
          includePageSizeInRequest && queryFnPageParams.initial
            ? pageSize
            : undefined,
        ...(queryFnPageParams.initial ? urlParams : {}),
      };

      const { data } = await axios.get<PaginatedResponse<T>>(
        queryFnPageParams.url,
        {
          headers: queryHeaders,
          params,
          paramsSerializer,
        },
      );

      if (queryKeyForItem) {
        const items = "results" in data ? data.results : data;
        items.forEach((item) => {
          const itemQueryKey = queryKeyForItem(item);
          queryClient.setQueryData(itemQueryKey, item);
        });
      }

      return data;
    },
    queryKey: [
      queryKey,
      urlParams ? JSON.stringify(urlParams) : undefined,
    ].filter(Boolean),
  });

  const items = useMemo(
    () =>
      (data?.pages.reduce(
        (prev: T[], curr) => [
          ...prev,
          ...("results" in curr ? curr.results : curr),
        ],
        [],
      ) || []) as T[],
    [data?.pages],
  );

  const fetchNextPageHandler = useCallback(async () => {
    if (!hasNextPage || status !== "success") {
      return;
    }
    return await fetchNextPage();
  }, [fetchNextPage, hasNextPage, status]);

  const isEmpty = useInfiniteQueryProps.isSuccess && !items.length;

  return {
    fetchNextPage: fetchNextPageHandler,
    hasNextPage,
    isEmpty,
    items,
    refetch,
    status,
    ...useInfiniteQueryProps,
  };
};
