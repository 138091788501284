import { useAuthContext } from "@/base/auth/providers/AuthProvider";
import { toaster } from "@/shared/components/Toaster";
import { useWebSocket } from "@/shared/hooks/useWebSocket";
import { type QueryKey, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";

import { UserNotificationContext } from "./UserNotificationContext";

type ProviderProps = React.PropsWithChildren<{
  invalidationKey: QueryKey;
}>;

type UserEventData =
  | {
      event_data: { id: number; message: string; subject: string };
      event_name: "NOTIFICATION_NEW";
    }
  | {
      event_data: { total_unread_notifications: number };
      event_name: "NOTIFICATION_COUNTER";
    };

export const UserNotificationProvider: React.FC<ProviderProps> = ({
  children,
  invalidationKey,
}) => {
  const { accessToken, isLoggedIn } = useAuthContext();
  const [totalUnreadNotifications, setTotalUnreadNotifications] = useState(0);
  const queryClient = useQueryClient();

  const onWebSocketReceivedData = useCallback(
    (data: null | UserEventData) => {
      if (data?.event_name === "NOTIFICATION_COUNTER") {
        setTotalUnreadNotifications(data.event_data.total_unread_notifications);
      } else if (data?.event_name === "NOTIFICATION_NEW") {
        queryClient.invalidateQueries({ queryKey: invalidationKey });
        toaster.create({
          description: (
            <span
              dangerouslySetInnerHTML={{ __html: data.event_data.message }}
            />
          ),
          duration: 9000,
          title: (
            <span
              dangerouslySetInnerHTML={{ __html: data.event_data.subject }}
            />
          ),
          type: "success",
        });
      }
    },
    [invalidationKey, queryClient],
  );

  const webSocketUrlParams = useMemo(
    () => ({ access_token: accessToken }),
    [accessToken],
  );

  useWebSocket({
    onReceivedData: onWebSocketReceivedData,
    skip: !isLoggedIn,
    url: import.meta.env.VITE_USER_CONSUMER_WS_URL,
    urlParams: webSocketUrlParams,
  });

  const value = useMemo(
    () => ({ totalUnreadNotifications }),
    [totalUnreadNotifications],
  );

  return (
    <UserNotificationContext.Provider value={value}>
      {children}
    </UserNotificationContext.Provider>
  );
};
