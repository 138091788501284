import type { AppRoute } from "@/base/router/types";

import { lazy } from "react";

const _NotificationListView = lazy(
  () => import("./notification/views/NotificationListView"),
);

export const notificationcenterRoutePaths = {
  notificationList: "/notifications",
} as const;

export const notificationcenterRoutes: AppRoute[] = [
  {
    element: <_NotificationListView />,
    enabled: true,
    path: notificationcenterRoutePaths.notificationList,
    permission: "all",
    presentation: "default",
    scope: "private",
  },
];
