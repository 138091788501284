import { createContext, useContext } from "react";

type ContextType = {
  totalUnreadNotifications: number;
};

export const UserNotificationContext = createContext<ContextType>({
  totalUnreadNotifications: 0,
});

export const useUserNotificationContext = () =>
  useContext(UserNotificationContext);
