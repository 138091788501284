import { createLogger } from "@/shared/utils/createLogger";
import { useCallback, useEffect, useMemo, useRef } from "react";

const logger = createLogger("useWebSocket");

const DEFAULT_RECONNECT_MS = 5000;

export type UseWebSocketProps<D> = {
  onClose?: WebSocket["onclose"];
  onError?: WebSocket["onerror"];
  onMessage?: WebSocket["onmessage"];
  onOpen?: WebSocket["onopen"];
  onReceivedData?: (data: D | null) => void;
  reconnectMs?: number;
  skip?: boolean;
  url: string;
  urlParams?: Record<string, null | number | string | undefined>;
};

const doubleSlashRegex = /([^:]\/)\/+/g;

export const useWebSocket = <D = unknown>({
  onClose,
  onError,
  onMessage,
  onOpen,
  onReceivedData,
  reconnectMs = DEFAULT_RECONNECT_MS,
  skip,
  url,
  urlParams,
}: UseWebSocketProps<D>) => {
  const websocketRef = useRef<null | WebSocket>(null);
  const reconnectTimeoutRef = useRef<null | number>(null);

  const stringUrlParams = useMemo(() => {
    if (skip) {
      return null;
    }

    const filteredUrlParams: Record<string, string> = {};
    for (const key in urlParams) {
      if (urlParams[key]) {
        filteredUrlParams[key] = "" + urlParams[key];
      }
    }
    return new URLSearchParams(filteredUrlParams).toString();
  }, [urlParams, skip]);

  const connect = useCallback(
    (url: string) => {
      logger.debug(`Connecting to ${url}`);

      if (reconnectTimeoutRef.current) {
        logger.debug("Cleaning up existing reconnect timeout");
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }

      websocketRef.current = new WebSocket(url);

      if (onOpen) websocketRef.current.onopen = onOpen;
      if (onClose) websocketRef.current.onclose = onClose;

      websocketRef.current.onmessage = (event) => {
        if (!websocketRef.current) {
          return;
        }

        logger.debug(`Retrieved message`, event);

        onMessage?.bind(websocketRef.current)(event);
        onReceivedData?.(event.data ? JSON.parse(event.data) : null);
      };

      websocketRef.current.onclose = (event) => {
        if (!websocketRef.current) {
          return;
        }

        logger.debug(
          `Socket is closed. Will try to reconnect in ${reconnectMs} milliseconds.`,
          event.reason,
        );

        onClose?.bind(websocketRef.current)(event);

        reconnectTimeoutRef.current = window.setTimeout(function () {
          logger.debug(`Started to reconnecting.`);
          connect(url);
        }, 5000);
      };

      websocketRef.current.onerror = (event) => {
        if (!websocketRef.current) {
          return;
        }
        onError?.bind(websocketRef.current)(event);
        websocketRef.current.close();
      };
    },
    [onClose, onError, onMessage, onOpen, onReceivedData, reconnectMs],
  );

  const websocketUrl = stringUrlParams
    ? `${url}/?${stringUrlParams}`.replace(doubleSlashRegex, "$1")
    : url;

  useEffect(() => {
    if (skip) {
      logger.debug("Skip is set to true. Will not connect to socket.");
      return;
    }
    connect(websocketUrl);
    return () => {
      if (reconnectTimeoutRef.current) {
        logger.debug("Removing reconnect timeout.");
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }

      if (websocketRef.current) {
        logger.debug("Removing listeners");
        websocketRef.current.onclose = null;
        websocketRef.current.onerror = null;
        websocketRef.current.onmessage = null;
        websocketRef.current.onopen = null;
        logger.debug("Closing connection");
        websocketRef.current.close();
        websocketRef.current = null;
      }
    };
  }, [connect, skip, websocketUrl]);
};
