import { createContext, useContext } from "react";

import type { AuthState } from "./reducer";

import { initialAuthState } from "./reducer";

const noop = () => {
  throw new Error("AuthProvider not found");
};

export type ExternalAuthContextValue = {
  accessToken?: null | string;
  isLoggedIn: boolean;
  jwtFormat: string;
};

export const ExternalAuthContext = createContext<ExternalAuthContextValue>({
  accessToken: null,
  isLoggedIn: false,
  jwtFormat: "Bearer",
});

export type InternalAuthContextValue = {
  authState: AuthState;
  setLoggedOut: () => void;
  setTokens: (input: { accessToken: string; refreshToken: string }) => void;
};

export const InternalAuthContext = createContext<InternalAuthContextValue>({
  authState: initialAuthState,
  setLoggedOut: noop,
  setTokens: noop,
});

export const useAuthContext = () => useContext(ExternalAuthContext);
export const useInternalAuthContext = () => useContext(InternalAuthContext);
