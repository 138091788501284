import type { UseJWTOptions } from "@/base/auth/hooks/useJWT";
import type { ReactNode } from "react";

import { useJWT } from "@/base/auth/hooks/useJWT";
import { clearAxiosAuthorizationHeader } from "@/base/auth/utils/clearAxiosAuthorizationHeader";
import { setAccessToken } from "@/base/auth/utils/setAccessToken";
import { setAxiosAuthorizationHeader } from "@/base/auth/utils/setAxiosAuthorizationHeader";
import { setRefreshToken } from "@/base/auth/utils/setRefreshToken";
import { createLogger } from "@/shared/utils/createLogger";
import { useCallback, useReducer } from "react";

import type { InternalAuthContextValue } from "./AuthContext";

import {
  setIsTokenHandledAction,
  setLoggedInAction,
  setLoggedOutAction,
} from "./actions";
import { ExternalAuthContext, InternalAuthContext } from "./AuthContext";
import { authReducer, initialAuthState } from "./reducer";

const logger = createLogger("AuthProvider");

type AuthProviderProps = {
  children: ReactNode;
  jwtFormat?: string;
} & Pick<UseJWTOptions, "getTokensFn">;

export const AuthProvider = ({
  children,
  getTokensFn,
  jwtFormat = "Bearer",
}: AuthProviderProps) => {
  const [authState, dispatch] = useReducer(authReducer, initialAuthState);
  const { isLoggedIn, isTokenHandled } = authState;

  const setLoggedIn = useCallback(
    (accessToken: string) => {
      logger.info("setLoggedIn", { accessToken });
      setAxiosAuthorizationHeader({ accessToken, jwtFormat: jwtFormat });
      dispatch(setLoggedInAction({ accessToken }));
    },
    [jwtFormat],
  );

  const setLoggedOut = useCallback(() => {
    logger.info("setLoggedOut");
    clearAxiosAuthorizationHeader();
    dispatch(setLoggedOutAction());
  }, []);

  const setIsTokenHandled = useCallback(() => {
    logger.info("setIsTokenHandled");
    dispatch(setIsTokenHandledAction());
  }, []);

  const setTokens = useCallback<InternalAuthContextValue["setTokens"]>(
    ({ accessToken, refreshToken }) => {
      setRefreshToken(refreshToken);
      setAccessToken(accessToken);
      setLoggedIn(accessToken);
    },
    [setLoggedIn],
  );

  useJWT({
    getTokensFn,
    isLoggedIn,
    onLoggedIn: setLoggedIn,
    onLoggedOut: setLoggedOut,
    onTokenHandled: setIsTokenHandled,
  });

  return (
    <InternalAuthContext.Provider
      value={{ authState, setLoggedOut, setTokens }}
    >
      <ExternalAuthContext.Provider
        value={{
          accessToken: authState.accessToken,
          isLoggedIn: authState.isLoggedIn,
          jwtFormat,
        }}
      >
        {isTokenHandled ? children : null}
      </ExternalAuthContext.Provider>
    </InternalAuthContext.Provider>
  );
};
